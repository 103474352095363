.background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 470px;
    /* max- */
    background-image: url('../../../img/About-Hero.jpg');
    background-size: cover;
    background-position: top;
}

.quote{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 670px;
    background-image: url('../../../img/Quote.png');
    background-size: cover;
}

.portfolio{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 470px;
    background-image: url('../../../img/Hero-Portfolio-page.jpg');
    background-size: cover;
    background-position: left;
}

@media (max-width:1280px) {
    .quote,
    .background,
    .portfolio {
        min-height: 470px;
    }
}

@media (max-width:768px) {
    .background,
    .quote,
    .portfolio {
        min-height: 200px;
    }
}